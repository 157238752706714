<template>
    <div class="takeClothesIndexView">
      <div class="topView">
        <div class="tView">
          <el-page-header
            style="width: 100%;padding:10px"
            @back="$router.go(-1)"
            content="入店"
          ></el-page-header>
        </div>
        <el-button type="primary" class="btn-add" @click="sendWashRecordPage"
          >入店记录</el-button
        >
      </div>
      <div class="content-package">
        <div class="package-left">
          <div class="left-top">
            <el-row :gutter="20" style="display:flex">
              <el-col :span="24" style="padding:10px 0 0 20px">
                <span>包裹码：</span>
                <el-input
                  v-model.trim="bundleNumber"
                  ref="query"
                  placeholder="请输入或扫描包裹码"
                  class="setWidth"
                  maxlength="20"
                  @keyup.enter.native="packageScanning"
                >
                </el-input>
                <span
                  style="padding-top:26px;font-size:18px;font-weight: 700;color:#DD5942"
                >
                  {{oldBundleNumber}}
                </span>
              </el-col>
            </el-row>
            <el-row style="color:#DD5942">
              <el-col
                style="font-size:20px;padding:10px 0px 0 80px;font-weight:700"
                >{{promptStatement}}</el-col
              >
            </el-row>
          </div>
          <div class="areaTitle">待入店包裹信息区域</div>
          <div class="packageInformation">
            <div class="information-item" @click="interactiveJump(6)">
              <span class="typeface" style="font-size: 26px;color:#101010"
                >{{statisticalData.waitFactoryBundleNum}}</span
              >
              <span>待入店包裹数量</span>
            </div>
            <div class="information-items">
              <div class="arrivedFactory" @click="interactiveJump(5)">
                <p style="padding-top:4px">已到店包裹：</p>
                <p class="quantity">{{statisticalData.arrivedFactoryBundleNum}}</p>
              </div>
              <div class="notYetFactory" @click="interactiveJump(4)">
                <p style="padding-top:4px">未到店包裹：</p>
                <p class="quantity">{{statisticalData.nonArrivedFactoryBundleNum}}</p>
              </div>
            </div>
          </div>
          <div class="stayIncoming">待入店包裹列表</div>
  
          <Table
             style="width: 100%;height:calc(100vh - 475px);overflow:auto"
            :border-bool="false"
            :button-type="false"
            :header-style="headerStyle"
            :orientation="'center'"
            :serial-number="true"
            :page="true"
            :table-data="tableData"
            :title-list="titleListData"
            />
        </div>
        <div class="package-right">
          <div class="left-content">
            <div class="left-top">
              <el-row :gutter="20" style="display:flex">
                <el-col :span="24" style="padding-top:10px">
                  <span>衣物码：</span>
                  <el-input
                    v-model.trim="clothesNum"
                    ref="query"
                    placeholder="请输入或扫描衣物码"
                    class="setWidth"
                    maxlength="20"
                    @keyup.enter.native="joinStore"
                  >
                  </el-input>
                  <span
                    style="padding-top:26px;font-size:18px;font-weight: 700;color:#DD5942"
                  >
                    {{oldClothesNum}}
                  </span>
                </el-col>
              </el-row>
              <el-row style="color:#DD5942">
                <el-col
                  style="font-size:20px;padding:10px 0px 0 80px;font-weight:700"
                  >{{oldClothesNumMesgger}}</el-col
                >
              </el-row>
            </div>
            <div class="contentLayout">
              <div class="content-center">
                <div class="clothingDetails">待入店衣物信息区域</div>
                <div class="packageInformation">
                  <div class="information-item" @click.stop="incoming(1)">
                    <span class="typeface" style="font-size: 26px;color:#101010"
                      >{{statisticalData.waitFactoryClothesNum}}</span
                    >
                    <span>待入店衣物数量</span>
                  </div>
                  <div class="information-items" @click.stop="incoming(2)">
                    <div class="arrivedFactoryone" style="padding: 0 10px">
                      <p style="padding-top:4px">今日待入店：</p>
                      <p class="right-quantity">{{statisticalData.todayWaitFactoryClothesNum}}</p>
                    </div>
                    <div class="notYetFactoryone" style="padding: 0 10px" @click.stop="incoming(3)">
                      <p style="padding-top:4px">超时待入店：</p>
                      <p class="right-quantity">{{statisticalData.timeOutWaitFactoryClothesNum}}</p>
                    </div>
                  </div>
                </div>
                <div style="border-right: 1px solid #EBEEF5;">
                  <div class="stayIncoming">待入店衣物列表</div>
                  <!-- 表格 -->
                  <Table1
                    style="width: 100%;height:calc(100vh - 475px);overflow:auto"
                    :stripe="true"
                    :border-bool="false"
                    :button-type="false"
                    :header-style="headerStyle"
                    :orientation="'center'"
                    :serial-number="true"
                    :page="true"
                    :table-data="clothesList"
                    :title-list="titleListData1"
                    />
                </div>
              </div>
              <div class="right-content" style="height:calc(100vh - 300px);overflow:auto">
                <div class="table-box">
                  <div class="view-table">
                    <div class="clothingDetails">衣物详情区域</div>
                    <ul>
                      <li>
                        <div>
                          <span>送洗工厂：</span>
                          <span>{{clothingDetails.factoryName}}</span>
                        </div>
                        <div>
                          <span>送洗时间：</span>
                          <span>{{clothingDetails.operateTime}}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>包裹号：</span>
                          <span>{{clothingDetails.bundleNumber}}</span>
                        </div>
                        <div>
                          <span>包裹出厂时间:</span>
                          <span>{{clothingDetails.bundleTime}}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>订单号：</span>
                          <span>{{clothingDetails.orderNumber}}</span>
                        </div>
                        <div>
                          <span>衣物号：</span>
                          <span>{{clothingDetails.outClothesNum}}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>衣物名称：</span>
                          <span>{{clothingDetails.clothesName}}</span>
                        </div>
                        <div>
                          <span>颜色：</span>
                          <span>{{clothingDetails.colorName}}</span>
                        </div>
                      </li>
                      <li>
                        <div style="color:#dd5942">
                          <span>是否返洗：</span>
                          <span>{{clothingDetails.isRewash}}</span>
                        </div>
                        <div style="color:#dd5942">
                          <span>是否加急：</span>
                          <span>{{clothingDetails.urgent ? '是' : '否'}}</span>
                        </div>
                      </li>
                      <li>
                        <div>
                          <span>服务：</span>
                          <span>{{clothingDetails.clothesServiceName}}</span>
                        </div>
                        <div style="display:flex">
                          <span >附件：</span>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="clothingDetails.blemishes"
                            placement="top-start"
                          >
                            <p
                              style="overflow: hidden;
                                text-overflow: ellipsis;
                                margin:0;
                                white-space: nowrap;width:130px;font-size:14px"
                            >
                              {{ clothingDetails.clothesAccessory ? clothingDetails.clothesAccessory : '无' }}
                            </p>
                          </el-tooltip>
                        </div>
                      </li>
                      <li>
                        <div style="display:flex">
                          <span>瑕疵：</span>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            :content="clothingDetails.blemishes"
                            placement="top-start"
                          >
                            <p
                              style="overflow: hidden;
                                text-overflow: ellipsis;margin:0;
                                white-space: nowrap;width:320px;font-size:14px"
                            >
                              {{ clothingDetails.blemishes }}
                            </p>
                          </el-tooltip>
                        </div>
                      </li>  
                      <li>
                        <div style="display:flex">
                              <span>衣物图片:</span>
                              <div v-if="clothingDetails.photos.length > 0">
                                <el-image
                                  v-for="(item, index) in clothingDetails.photos"
                                  v-show="index < 5"
                                  :key="index"
                                  class="img"
                                  :preview-src-list="clothingDetails.photos"
                                  :src="item"
                                />
                                <span
                                  v-show="
                                  clothingDetails.photos
                                      ? clothingDetails.photos.length > 5
                                      : false
                                  "
                                  class="imgs"
                                >
                                  <el-image
                                    v-if="clothingDetails.photos.length >= 5"
                                    class="img"
                                    :preview-src-list="clothingDetails.photos"
                                    :src="clothingDetails.photos[5]"
                                    style="position: absolute; left: 0; opacity: 0"
                                  />
                                  +{{ clothingDetails.photos.length - 5 }}
                                </span>
                              </div>
                              <span v-else>暂无图片</span>
                            </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="userDetails">
        <div class="sendWashRecordView" v-if="q">
          <inboundPackage @back="back"/>
        </div>
        <div class="sendWashRecordView" v-if="nonArrival">
          <toBeEntered @back="back" :packageState="packageState"/>
        </div>
        <div class="sendWashRecordView" v-if="stockPending">
          <beforeTheShop @back="back" :packageState="packageState"/>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  import {
    packageScanning,
    incomingPackage,
    statistics,
    joinStore,
  } from "@/api/clothes/order";
  import Table from "@/components/table2";
  import Table1 from "@/components/table2";
  import toBeEntered from "./components/toBeEntered";
  import beforeTheShop from "./components/beforeTheShop";
  import inboundPackage from "./components/inboundPackage";
  
  export default {
    name: "index",
    components: {
      Table,
      Table1,
      toBeEntered,
      beforeTheShop,
      inboundPackage
    },
    data() {
      return {
        q: false,
        clothesNum: "",
        loading: false,
        headerStyle: {
          fontSize: "14px",
          background: "#FFF",
          color: "black",
        },
        titleListData: [
          {
            prop: "bundleNumber",
            label: "包裹号",
            width: 130,
          },
          {
            prop: "factoryName",
            label: "工厂",
          },
          {
            prop: "bundleStateDesc",
            label: "包裹状态",
          },
          {
            prop: "clothesCount",
            label: "件数",
          },
        ],
        tableData: [],
        statisticalData:{},
        titleListData1: [
          {
            prop: "outClothesNum",
            label: "衣物号",
            width: 120,
          },
          {
            prop: "factoryName",
            label: "工厂",
          },
          {
            prop: "clothesName",
            label: "衣物名称",
          },
        ],
        clothingDetails:{
          photos:[]
        },
        clothesList: [],
        oldBundleNumber: '',
        promptStatement:'信息提示区域',
        oldClothesNum: '',
        oldClothesNumMesgger:'信息提示区域',
        stockPending: false,
        nonArrival: false,
        bundleNumber: '', 
        packageState:''
      };
    },
    created() {
      this.incomingPackage();
      this.statistics();
    },
    mounted() {
      this.$nextTick(function() {
        this.$refs.query.focus();
      });
    },
    methods: {
      //入店记录
      sendWashRecordPage() {
        this.q = true;
      },
      //包裹交互
      interactiveJump(val) {
        this.stockPending = true;
        this.packageState = val
      },
      //待入店衣物交互
      incoming(val){
        this.nonArrival = true;
        this.packageState = val
        console.log(val,this.packageState)
      },
      back() {
        this.stockPending = false;
        this.nonArrival = false;
        this.q = false;
      },
      //入店包裹扫码
      packageScanning(){
        if (!this.bundleNumber) {
          this.$message.error("请输入包裹号");
          return;
        }
        let params = {
          bundleNumber: this.bundleNumber
        }
        packageScanning(params).then((res) => {
          if(res.data.code == 0){
            let data = res.data.data
            this.clothesList = data.factoryClothesList
            this.statisticalData = data
            this.promptStatement = res.data.msg
            this.tableData =  data.factoryBundleList ? data.factoryBundleList : []
          }
          this.oldBundleNumber  = this.bundleNumber
          this.bundleNumber = ''
          this.audio.btts(res.data.msg);
        })
      },
      //衣物扫码
      joinStore(){
        if (!this.clothesNum) {
          this.$message.error("请输入条码号");
          return;
        }
        let params = {
          outClothesNum: this.clothesNum
        }
        joinStore(params).then((res) => {
          let data = res.data.data
          if(res.data.code == 0){
            let tableList = this.clothesList
            let index = tableList.findIndex((item) => {
              if(item.outClothesNum == this.clothesNum){
                return item
              }
            })
            this.clothesList.splice(index,1)
            this.statisticalData = data.statistics ? data.statistics : {}
            this.clothingDetails = data.clothes ?  data.clothes : {}
          }
          this.oldClothesNumMesgger = res.data.msg
          this.oldClothesNum = this.clothesNum
          this.clothesNum = ''
          this.audio.btts(res.data.msg);
        })
  
      },
      //待入店包裹列表
      incomingPackage(){
         let params = {
          type: 2
         }
         incomingPackage(params).then((res) => {
          this.tableData = res.data.data
         })
      },
      //店包裹衣物统计
      statistics(){
        let params = {
          type: 2
         }
        statistics(params).then((res) => {
          this.statisticalData = res.data.data
        })
      }
    },
  };
  </script>
  
  <style scoped lang="scss">
  .takeClothesIndexView {
    position: relative;
    overflow: auto;
    font-family: PingFangSC-Regular;
  
    > .topView {
      padding: 10px 20px;
      overflow: hidden;
  
      > .tView {
        float: left;
        color: #333;
        text-align: left;
        font-size: 22px;
        font-family: PingFang-SC-Bold;
        line-height: 40px;
      }
  
      > .btn-add {
        float: right;
      }
    }
  }
  
  div {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  
  .sendWashRecordView {
    position: fixed;
    left: 70px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background: #fff;
    z-index: 100;
    padding: 0px 20px 25px 25px;
    overflow: auto;
  
    > .topView {
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;
      padding: 20px 25px 0 25px;
      z-index: 100;
  
      > .backView {
        height: 30px;
  
        > .btn-back {
          font-size: 14px;
          color: #666;
          height: 30px;
          padding: 0;
          border: none;
          text-align: left;
        }
      }
  
      > .contentView {
        display: flex;
        position: relative;
        height: 55px;
        padding: 10px 0 15px 0;
        overflow: hidden;
  
        > .tView {
          margin-top: 0;
          margin-right: 20px;
          width: 150px;
          font-family: PingFang-SC-Bold;
          font-size: 24px;
          color: #333;
          text-align: left;
          line-height: 40px;
        }
      }
    }
  }
  
  //2.0
  .content-package {
    display: flex;
    height: calc(100vh - 180px);
    flex-direction: row;
    margin-top: 20px;
    padding: 0 10px;
    .package-left {
      width: 38%;
      margin-right: 15px;
      border: 1px solid #EBEEF5;
    }
    .package-right {
      width: 62%;
      padding-right: 10px;
      border: 1px solid #EBEEF5;
      .contentLayout {
        display: flex;
        flex-direction: row;
        .content-center {
          width: 45%;
        }
        .right-content {
          width: 55%;
          margin-left: 10px;
          .table-box {
            display: flex;
            > div {
              flex: 1;
            }
          }
          .view-table {
            border: 1px solid #EBEEF5;
            ul {
              padding: 0 5px;
              margin: 0 0 10px 0;
              li {
                display: flex;
                border-bottom: 1px solid #EBEEF5;
  
                div{
                  flex: 1;
                  line-height: 50px;
                  padding-left: 5px;
                  span {
                    font-size: 14px;
                  }
                }
              }
  
              li:nth-child(even) {
                background-color: #f2f2f2;
              }
            }
          }
        }
      }
    }
    .setWidth {
      width: 200px;
    }
  }
  .left-top {
    height: 105px;
    padding: 10px;
    width: 100%;
  }
  .clothingDetails {
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #3370ff;
    background-color: #3370ff;
    color: #fff;
  }
  .areaTitle {
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid #3370ff;
    background-color: #3370ff;
    color: #fff;
  }
  .packageInformation {
    display: flex;
    flex-direction: row;
    .information-item {
      width: 40%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 20px 0;
      line-height: 30px;
      border-bottom: 1px solid #EBEEF5;
    }
    .information-items {
      width: 60%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      line-height: 30px;
      .arrivedFactory {
        display: flex;
        flex-direction: row;
        line-height: 10px;
        padding: 0 20px;
        font-size: 16px;
        border-bottom: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 0px solid #EBEEF5;
      }
      .notYetFactory {
        display: flex;
        flex-direction: row;
        line-height: 10px;
        padding: 0 20px;
        color: #dd5942;
        font-size: 16px;
        border: 1px solid #EBEEF5;
        border-top: 0px solid #EBEEF5;
        border-right: 0px solid #EBEEF5;
      }
      .arrivedFactoryone {
        display: flex;
        flex-direction: row;
        line-height: 10px;
        padding: 0 20px;
        font-size: 16px;
        border: 1px solid #EBEEF5;
      }
      .notYetFactoryone {
        display: flex;
        flex-direction: row;
        line-height: 10px;
        padding: 0 20px;
        color: #dd5942;
        font-size: 16px;
        border: 1px solid #EBEEF5;
        border-top: 0px solid #EBEEF5;
      }
      .quantity {
        padding-left: 30%;
        font-size: 20px;
      }
      .right-quantity {
        padding-left: 5%;
        font-size: 20px;
      }
      .typeface {
        font-size: 26px;
        color: #101010;
      }
    }
  }
  .stayIncoming {
    text-align: center;
    padding: 10px 0;
    font-size: 16px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee;
    color: #101010;
  }
  .img {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    padding: 0 !important;
    margin-right: 5px !important;
  }
  .imgs {
    display: inline-block;
    vertical-align: bottom;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #e8e8e8;
    cursor: pointer;
    position: relative;
  }
  
  //动画过程
  .userDetails-enter {
    animation: fullscreen-dialog-fade-in 0.9s ease;
  }
  
  .userDetails-leave {
    animation: fullscreen-dialog-fade-out 0.9s ease forwards;
  }
  
  .userDetails-enter-active {
    animation: fullscreen-dialog-fade-in 0.9s ease;
  }
  
  .userDetails-leave-active {
    animation: fullscreen-dialog-fade-out 0.9s ease forwards;
  }
  
  @keyframes fullscreen-dialog-fade-in {
    0% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  
  @keyframes fullscreen-dialog-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
  }
  </style>
  