<template>
  <div class="incomingClothes">
    <el-row class="tab" :gutter="20">
      <el-col :span="8">
        <el-page-header content="待入店衣物" @back="back" />
      </el-col>
    </el-row>
    <div v-loading="loading">
      <el-row :gutter="20" style="padding: 20px">
        <el-col style="display:flex;padding: 15px;font-size: 16px;color:#000">
          <span>全部待入店衣物数量：</span>
          <span>{{ dataList.total }}件</span>
          <span style="padding-left: 20px">超时待入店衣物数量：</span>
          <span>{{ dataList.otherTotal }}件</span>
          <div style="margin:-10px 0 0 20px">
            <el-select v-model="timeOutFlag" clearable placeholder="超时待入店">
              <el-option
                v-for="item in overtimeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              style="padding: 0 20px"
              v-model="factoryId"
              clearable
              placeholder="请选择工厂"
            >
              <el-option
                v-for="item in factoryList"
                :key="item.factoryId"
                :label="item.factoryName"
                :value="item.factoryId"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="determine">确定</el-button>
          </div>
        </el-col>
      </el-row>
      <Table
        :stripe="true"
        :border-bool="false"
        :button-type="true"
        :current-value="current"
        :header-style="headerStyle"
        :orientation="'center'"
        :serial-number="true"
        :height="'62vh'"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @operationClick="operationClick"
      />
    </div>
    <!--衣物详情-->
    <pop-view
      id="order-detailViewPopView"
      :show="detailView"
      width="700px"
      title="衣物详情"
      @cancel="detailView = false"
    >
      <div class="menuView">
        <button
          :class="{ select: currentTab === item.val }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="handleDetailTab(item.val)"
        >
          {{ item.title }}
        </button>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px"
        v-if="currentTab === 'info'"
      >
        <div style="display: flex">
          <div class="infoView">
            <div>
              <label class="label-leftText">衣物名称：</label>
              <label>{{ clothes.clothesName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物号：</label>
              <label>{{ clothes.clothesNum }}</label>
            </div>
            <div>
              <label class="label-leftText">洗涤方式：</label>
              <label>{{ clothes.clothesServiceName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物状态：</label>
              <label>{{ clothes.clothesStatus }}</label>
            </div>
            <div>
              <label class="label-leftText">洗后效果：</label>
              <el-tooltip class="item" effect="dark" :content="clothes.effects" placement="top">
                <label class="label-ellipsis">{{ clothes.effects }}</label>
              </el-tooltip>
            </div>
            <div>
              <label class="label-leftText">是否售后：</label>
              <label>{{ clothes.isAfterSale }}</label>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">颜色：</label>
              <label>{{ clothes.colorName }}</label>
            </div>
            <div>
              <label class="label-leftText">品牌：</label>
              <label>{{ clothes.brandName }}</label>
            </div>
            <div>
              <label class="label-leftText">衣物价格：</label>
              <label>¥{{ clothes.currentPrice }}</label>
            </div>
            <div>
              <label class="label-leftText">单价：</label>
              <label v-if="clothes.unitPrice">¥{{ clothes.unitPrice }}</label>
              <label v-else>0</label>
            </div>
            <div>
              <label class="label-leftText">平方数：</label>
              <label v-if="clothes.squareNum">{{ clothes.squareNum }}m²</label>
              <label v-else>0m²</label>
            </div>
            <div>
              <label class="label-leftText">售后状态：</label>
              <label>{{ clothes.afterSaleStatusDesc }}</label>
            </div>
          </div>
        </div>
        <div style="display: flex">
          <div class="infoView">
            <div>
              <label class="label-leftText">瑕疵：</label>
              <el-tooltip class="item" effect="dark" :content="clothes.blemishes" placement="top">
                <label class="label-ellipsis">{{ clothes.blemishes }}</label>
              </el-tooltip>
            </div>
            <div>
              <label class="label-leftText">备注：</label>
              <el-tooltip class="item" effect="dark" :content="clothes.remark" placement="top">
                <label class="label-ellipsis">{{ clothes.remark }}</label>
              </el-tooltip>
            </div>
          </div>
          <div class="infoView">
            <div>
              <label class="label-leftText">预计取衣时间：</label>
              <label>{{ clothes.estimatedWashTime }}</label>
            </div>
            <div>
              <label class="label-leftText">工厂时效：</label>
              <label>{{ clothes.factoryAging }}天</label>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            margin-top: 20px;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
          "
        >
          <div v-if="clothes.photos !== null">
            <el-image
              style="margin-left: 10px; width: 22%; margin-bottom: 10px"
              v-for="url in clothes.photos"
              :key="url"
              :src="url"
              :preview-src-list="clothes.photos"
              lazy
            ></el-image>
          </div>
        </div>
      </div>
      <div
        class="contentView"
        style="margin-top: 20px"
        v-if="currentTab === 'status'"
      >
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in clothesStatusList"
            :key="index"
            color="#0bbd87"
            :timestamp="activity.operateTime"
          >
            {{ activity.description }}
          </el-timeline-item>
        </el-timeline>
      </div>
    </pop-view>
  </div>
</template>

<script>
import Table from "@/components/table2";
import { saveFactory } from "@/api/afterSale/afterSale";
import { wrapClothes, clothesDetails } from "@/api/clothes/order";
import PopView from "@/components/PopView";
export default {
  name: "",
  components: {
    Table,
    PopView,
  },
  props: {
    packageState: {
      type: Number,
    },
  },
  data() {
    return {
      headerStyle: {
        fontSize: "14px",
        background: "#EEF0F1",
        color: "#363636",
      },
      titleListData: [
        {
          prop: "factoryName",
          label: "清洗工厂",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
        },
        {
          prop: "clothesName",
          label: "衣物名称",
        },
        {
          prop: "washTime",
          label: "出厂时间",
        },
        {
          prop: "washDays",
          label: "出厂天数",
        },
        {
          prop: "bundleClothesStatusDesc",
          label: "衣物状态",
        },
        {
          prop: "bundleStateDesc",
          label: "包裹状态",
        },
      ],
      tableData: [],
      total: 0,
      current: 1,
      factoryList: [],
      factoryId: "",
      timeOutFlag: "",
      todayFlag:'',
      overtimeList: [
        {
          value: "1",
          label: "超时待入店",
        },
      ],
      loading: false,
      dataList: {},
      repairLoading: false,
      clothes: {},
      clothesStatusList: [],
      tabs: [
        { val: "info", title: "信息" },
        { val: "status", title: "状态" },
      ],
      currentTab: "info",
      detailView: false,
    };
  },
  watch: {
    packageState: {
      handler() {
        this.paramsState()
      },
      immediate: true,
    },
  },
  methods: {
    paramsState(){
      let val = this.packageState
      if(val == 2){
        this.todayFlag = 1
      }else if(val == 3){
        this.timeOutFlag = '1'
        console.log('超时')
      }else {
        this.todayFlag = ''
        this.timeOutFlag = ''
      }
      this.current =  1
      this.wrapClothes();
      this.saveFactory();
    },
    //确定
    determine() {
      this.current = 1;
      this.wrapClothes();
    },
    //分页查询包裹衣物
    wrapClothes() {
      let params = {
        queryFlag: 2,
        timeOutFlag: this.timeOutFlag,
        factoryId: this.factoryId,
        current: this.current,
        todayFlag: this.todayFlag
      };
      this.loading = true;
      wrapClothes(params).then((res) => {
        this.dataList = res.data.data;
        this.total = res.data.data.total;
        this.tableData = res.data.data.records?.map((item) => {
          item.buttonList = [
            {
              name: "查看详情",
              size: "medium",
              type: "text",
              code: "details",
              width: "180",
            },
          ];
          item.washDays = item.washDays + "";
          return item;
        });
        this.loading = false;
      });
    },
    handleCurrentChange(val) {
      this.current = val;
      this.wrapClothes();
    },
    //表格按钮
    operationClick({ code, row }) {
      this.clothesDetails(row);
      this.detailView = true;
    },
    //查询衣物详情
    clothesDetails(row) {
      clothesDetails(row.clothesNum).then((res) => {
        let _this = this;
        _this.clothesStatusList = [];
        _this.clothes = res.data.data;
        _this.clothes.clothesStatusList.forEach(function(item, index) {
          if (item.active) {
            _this.clothesStatusList.push(item);
          }
        });
      });
    },
    handleDetailTab(val) {
      this.currentTab = val;
    },
    back() {
      this.$emit("back");
    },
    //获取工厂名称
    async saveFactory() {
      const { data: res } = await saveFactory();
      if (res.code != 0) return this.$message.error("获取工厂名称失败");
      this.factoryList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.incomingClothes {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 20px 25px 0 25px;
  z-index: 100;
}
#order-detailViewPopView > .popView-contentView {
  > .menuView {
    position: absolute;
    width: 88px;
    display: flex;
    top: 45px;
    left: 0;
    right: 0;
    margin: auto;

    > button {
      font-size: 16px;
      color: #333;
      flex: 1;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    > button.select {
      position: relative;
      color: #3370ff;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
    }

    > button.select:after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0px;
      position: absolute;
      width: 24px;
      height: 2px;
      background: #3370ff;
      margin: auto;
      border-radius: 1px;
    }
  }

  > .contentView {
    .infoView {
      padding: 0 20px 10px 20px;
      line-height: 30px;
      font-size: 14px;

      > div {
        display: flex;
        color: #333;

        > label {
          display: block;
        }

        > .label-leftText {
          width: 99px;
          min-width: 80px;
          color: #999;
        }
      }
    }
  }
}
.label-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 210px;
}
</style>
