<template>
  <div class="takeClothesIndexView">
    <div class="topView">
      <div class="tView">
        <el-page-header
          style="width: 100%;padding:10px"
          @back="$router.go(-1)"
          content="入店"
        ></el-page-header>
      </div>
      <el-button type="primary" class="btn-add" @click="sendWashRecordPage"
        >入店记录</el-button
      >
    </div>
    <div class="searchView">
      <el-row :gutter="20">
        <el-col :span="10">
          <el-table
            ref="staffTable"
            v-loading="waitingLoading"
            element-loading-text="加载中..."
            :data="waitingList"
            border
            class="staffTable"
          >
            <el-table-column
              prop="outClothesNum"
              align="center"
              label="衣物号"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="clothesName"
              align="center"
              label="衣物名称"
              width="150"
            >
            </el-table-column>
            <el-table-column
              prop="clothesServiceName"
              align="center"
              label="服务项目"
            ></el-table-column>
            <el-table-column prop="blemishes" align="center" label="衣物描述">
            </el-table-column>
            <el-table-column
              prop="outFactoryTime"
              align="center"
              label="出厂时间 "
              width="150"
            ></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="4">
          <div style="display:flex">
            <el-input
              placeholder="请输入条码号"
              ref="query"
              v-model.trim="clothesNum"
              style="width: 150px;margin-right: 10px"
              @keyup.enter.native="handleClothes"
            ></el-input>
            <el-button
              type="primary"
              :loading="handleClothesLoading"
              @click="handleClothes"
              >确认</el-button
            >
          </div>
          <div style="text-align: center;margin-top: 20px;">
            <el-button type="primary" @click="handleShelves"
              >确认入店</el-button
            >
          </div>
          <div style="text-align: center;margin-top: 30px;">
            <div>待入店数量</div>
            <div style="font-size: 24px;color: red">
              {{ waitingList.length }}
            </div>
            <div style="margin-top: 10px">已扫码数量</div>
            <div style="font-size: 24px;color: green">
              {{ tableData_amount.length }}
            </div>
            <div style="text-align: center;margin-top: 20px;">
              <el-button
                type="warning"
                :disabled="multipleSelection.length <= 0"
                @click="handleRemoveClothes"
                >确认移除</el-button
              >
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <el-table
            ref="selectedStaffTable"
            v-loading="loading"
            :data="tableData_amount"
            border
            fit
            class="selectedStaffTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="clothesName"
              align="center"
              label="衣物名称"
              width="120"
            ></el-table-column>
            <el-table-column
              prop="clothesServiceName"
              align="center"
              label="洗涤服务"
            ></el-table-column>
            <el-table-column
              prop="colorName"
              align="center"
              label="颜色"
            ></el-table-column>
            <el-table-column
              prop="outClothesNum"
              align="center"
              width="120"
              label="衣物号"
            ></el-table-column>
            <el-table-column
              prop="clothesStatus"
              align="center"
              label="状态"
            ></el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <transition name="userDetails">
      <div class="sendWashRecordView" v-if="q">
        <div class="topView">
          <div class="backView">
            <!--                        <el-button class="btn-back" icon="el-icon-arrow-left" @click="q = false">返回</el-button>-->
            <el-page-header
              @back="q = false"
              content="入店记录"
            ></el-page-header>
          </div>
        </div>
        <div class="contentView" style="height: 669px;">
          <el-table
            style="width: 100%;"
            ref="selectedStaffTable"
            v-loading="sendWashRecordLoading"
            :data="sendWashRecordList"
            border
          >
            <el-table-column
              prop="serialNum"
              align="center"
              label="流水号"
            ></el-table-column>
            <el-table-column
              prop="storeUserName"
              align="center"
              label="送洗人"
            ></el-table-column>
            <el-table-column
              prop="operateNum"
              align="center"
              width="100"
              label="件数"
            ></el-table-column>
            <el-table-column
              prop="sendWashFactory"
              align="center"
              label="送洗工厂"
            ></el-table-column>
            <el-table-column
              prop="sendWashFactoryUserName"
              align="center"
              label="接收人"
            ></el-table-column>
            <el-table-column
              prop="operateTime"
              align="center"
              width="280"
              label="操作时间"
            ></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="inShopRecordDetail(scope.row)"
                  >查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px;float: right;">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </transition>
    <el-dialog
      style="height: auto;"
      :visible.sync="dialogTableVisible"
      width="70%"
    >
      <el-table
        header-row-class-name="thClassNameDialog"
        row-class-name="trClassNameDialog"
        :data="gridData"
        v-loading="inShopRecordDetailLoading"
      >
        <el-table-column
          property="serialNum"
          align="center"
          width="180"
          label="入店单号"
        ></el-table-column>
        <el-table-column
          prop="clothesNumber"
          align="center"
          label="内部衣物号"
        ></el-table-column>
        <el-table-column
          prop="outClothesNumber"
          align="center"
          label="外部衣物号"
        ></el-table-column>
        <el-table-column
          property="clothesName"
          align="center"
          label="衣物名称"
        ></el-table-column>
        <el-table-column
          property="clothesServiceName"
          align="center"
          label="服务名称"
        ></el-table-column>
        <el-table-column
          property="blemisheDesc"
          align="center"
          label="衣物描述"
        ></el-table-column>
        <el-table-column
          property="storeUserName"
          align="center"
          label="本次入店人"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  waitOperate,
  queryClothes,
  operate,
  clothesOperateRecord,
  listClothesOperateRecordDetais,
} from "@/api/clothes/order";

export default {
  name: "index",
  data() {
    return {
      staffTemp: {
        phone: "",
        nickName: "",
        staffTypeId: "",
      },
      staffList: [],
      selectedStaffList: [],
      staffData: [],
      selectedStaffData: [],
      tableKey: 0,
      rowKey: "rowKey",
      q: false,
      type: 5,
      waitingList: [],
      waitingLoading: false,
      tableData_amount: [],
      clothesNum: "",
      handleClothesLoading: false,
      loading: false,
      sendWashLoading: false,
      sendWashRecordLoading: false,
      sendWashRecordList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0, // 总数量,
      dialogTableVisible: false,
      inShopRecordDetailLoading: false,
      gridData: [],
      serialNum: "",
      // 移除衣物列表
      multipleSelection: [],
      tableHeight: 50,
    };
  },
  created() {
    this.listWaitToWash();
  },
  mounted() {
    this.$nextTick(function() {
      this.$refs.query.focus();
      this.tableHeight =
        window.innerHeight - this.$refs.staffTable.$el.offsetTop - 130;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function() {
        self.tableHeight =
          window.innerHeight - self.$refs.staffTable.$el.offsetTop - 130;
      };
    });
  },
  methods: {
    // 确定送洗
    handleClothes() {
      this.handleClothesLoading = true;
      if (!this.clothesNum) {
        this.handleClothesLoading = false;
        this.$message.error("请输入条码号");
        return;
      }
      let aa = this.tableData_amount.find(
        (c) => c.outClothesNum === this.clothesNum
      );
      if (aa) {
        this.clothesNum = "";
        this.handleClothesLoading = false;
        this.$message.error("已经扫描过了");
        this.audio.btts("重复扫描");
        return;
      }
      this.handleClothesLoading = false;
      this.loading = true;
      queryClothes(this.type, this.clothesNum).then((response) => {
        if (response.data.code === 0) {
          // 现有的集合移除
          // 方法二
          var index = this.waitingList.findIndex((item) => {
            if (item.outClothesNum === this.clothesNum) {
              return true;
            }
          });
          this.waitingList.splice(index, 1);

          this.clothesNum = "";
          this.tableData_amount.push(response.data.data);
          this.audio.btts(response.data.data.clothesName);
        } else {
          this.clothesNum = "";
          this.$message.error(response.data.msg);
          this.audio.btts("当前衣物不能入店");
        }
        this.loading = false;
      });
    },
    listWaitToWash() {
      this.waitingLoading = true;
      waitOperate(this.type).then((res) => {
        console.log(res);
        this.waitingList = res.data.data;
        this.waitingLoading = false;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    // 确认移除衣物
    handleRemoveClothes() {
      let indexes = this.tableData_amount
        // 没有，否则返回 -1
        .map((n, i) => (this.multipleSelection.indexOf(n) > -1 ? i : -1))
        // 找出不是 -1 的，即所有偶数的序号
        .filter((i) => i >= 0);
      indexes = indexes.reverse();
      for (let i = 0; i < indexes.length; i++) {
        this.tableData_amount.splice(indexes[i], 1);
      }
      // 逆向添加
      this.multipleSelection.forEach((item) => {
        this.waitingList.unshift(item);
      });
    },
    handleShelves() {
      if (this.tableData_amount.length === 0) {
        this.$message.error("无衣物入店");
        return;
      }
      let datas = [];
      this.tableData_amount.forEach(function(item) {
        let data = {
          id: item.id, // 衣物主键Id
          clothesNumber: item.clothesNum, // 衣物编号
          orderNumber: item.orderNumber, //订单编号
        };
        datas.push(data);
      });
      let dd = {
        clothesList: datas,
        type: this.type,
      };
      this.loading = true;
      operate(dd).then((response) => {
        if (response.data.code === 0) {
          if (response.data.data) {
            // this.reload()
            this.serialNum = response.data.data.serialNum;
            this.gridData = this.tableData_amount;
            this.tableData_amount = [];
            this.$message.success("衣物入店成功");
          }

          this.loading = false;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.sendWashRecordPage();
    },
    // 获取送洗记录
    sendWashRecordPage() {
      this.q = true;
      this.sendWashRecordLoading = true;
      const params = new URLSearchParams();
      params.append("current", this.currentPage);
      params.append("size", this.pageSize);
      params.append("type", this.type);
      clothesOperateRecord(params).then((res) => {
        this.sendWashRecordList = res.data.data.operateRecordList;
        this.storeUserName = res.data.data.storeUserName;
        this.storeName = res.data.data.storeName;
        this.total = res.data.data.total;
        this.sendWashRecordLoading = false;
      });
    },
    inShopRecordDetail(row) {
      this.dialogTableVisible = true;
      this.inShopRecordDetailLoading = true;
      this.sendStoreUserName = row.storeUserName;
      listClothesOperateRecordDetais(row.serialNum).then((res) => {
        console.log("znemn ", res);
        this.gridData = res.data.data.clothes;
        this.inShopRecordDetailLoading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.takeClothesIndexView {
  position: relative;
  overflow: auto;
  font-family: PingFangSC-Regular;

  > .topView {
    padding: 10px 20px;
    overflow: hidden;

    > .tView {
      float: left;
      color: #333;
      text-align: left;
      font-size: 22px;
      font-family: PingFang-SC-Bold;
      line-height: 40px;
    }

    > .btn-add {
      float: right;
    }
  }

  > .searchView {
    padding: 10px 20px 5px 20px;
    .staffTable {
      height: calc(100vh - 180px);
      overflow: auto;
    }
    .selectedStaffTable {
      height: calc(100vh - 180px);
      overflow: auto;
    }
  }
}

div {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sendWashRecordView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  padding: 60px 20px 25px 25px;
  overflow: auto;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 115px;*/
    height: 50px;
    padding: 20px 25px 0 25px;
    z-index: 100;
    /*box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);*/

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }

    > .contentView {
      display: flex;
      position: relative;
      height: 55px;
      padding: 10px 0 15px 0;
      overflow: hidden;

      > .tView {
        margin-top: 0;
        margin-right: 20px;
        width: 150px;
        font-family: PingFang-SC-Bold;
        font-size: 24px;
        color: #333;
        text-align: left;
        line-height: 40px;
      }
    }
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
