<template>
  <div class="beforeTheFactory">
    <el-row class="tab" :gutter="20">
      <el-col :span="8">
        <el-page-header :content="title" @back="back" />
      </el-col>
    </el-row>
    <el-row :gutter="20" style="padding: 20px">
      <el-col :span="9" style="padding: 15px;font-size: 14px;color:#000">
        <span>{{ titleContent }}</span>
        <span>{{ total }}个</span>
      </el-col>
    </el-row>
    <div v-loading="loading">
      <Table
        :stripe="true"
        :border-bool="false"
        :button-type="true"
        :current-value="current"
        :header-style="headerStyle"
        :orientation="'center'"
        :serial-number="true"
        :height="'62vh'"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @operationClick="operationClick"
      />
    </div>
    <transition name="userDetails">
      <div class="sendWashRecordView" v-if="drawer">
        <Details
          @close="close"
          :height="height"
          :length="length"
          :dataList="dataList"
          :imageList="imageList"
          :viewSwitch="viewSwitch"
          @switchClose="switchClose"
        >
          <el-col :span="4">
            <span>包裹号：</span>
            <span>{{ dataList.bundleNumber }}</span>
          </el-col>
          <el-col :span="3">
            <span>送洗工厂：</span>
            <span>{{ dataList.factoryName }}</span>
          </el-col>
          <el-col :span="5">
            <span>送洗时间：</span>
            <span>{{ dataList.washTime }}</span>
          </el-col>
          <el-col :span="4">
            <span>送洗人：</span>
            <span>{{ dataList.washUserName }}</span>
          </el-col>
          <el-col :span="4">
            <span>包裹状态：</span>
            <span>{{ dataList.bundleStateDesc }}</span>
          </el-col>
          <el-col :span="3" style="display: flex">
            <span>包裹图片：</span>
            <el-button type="text" style="margin-top: -2px" @click="seeClick"
              >查看</el-button
            >
          </el-col>
        </Details>
      </div>
    </transition>
  </div>
</template>

<script>
import Table from "@/components/table2";
import Details from "@/components/logisticsDetails";
import { paginationPackage, packageDetails } from "@/api/clothes/order";
export default {
  name: "",
  components: {
    Table,
    Details,
  },
  props: {
    packageState: {
      type: Number,
    },
  },
  data() {
    return {
      headerStyle: {
        fontSize: "14px",
        background: "#EEF0F1",
        color: "#363636",
      },
      titleListData: [
        {
          prop: "factoryName",
          label: "清洗工厂",
        },
        {
          prop: "bundleNumber",
          label: "包裹号",
        },
        {
          prop: "washTime",
          label: "出厂时间",
        },
        {
          prop: "washDays",
          label: "出厂天数",
        },
        {
          prop: "userName",
          label: "物流员",
        },
        {
          prop: "bundleStateDesc",
          label: "包裹状态",
        },
      ],
      tableData: [],
      total: 0,
      current: 1,
      drawer: false,
      direction: "rtl",
      viewSwitch: false,
      height: "",
      length: null,
      imageList: [],
      dataList: {},
      loading: false
    };
  },
  watch: {
    packageState: {
      handler() {
        this.titleState();
        this.paginationPackage();
      },
      immediate: true,
    },
  },
  methods: {
    titleState() {
      let val = this.packageState;
      if (val == 4) {
        this.title = "未到店包裹";
        this.titleContent = "未到店包裹数量：";
      } else if (val == 5) {
        this.title = "已到店包裹";
        this.titleContent = "已到店包裹数量：";
      } else {
        this.title = "待入店包裹";
        this.titleContent = "待入店包裹数量：";
      }
      this.current =  1
    },
    //状态分页查询包裹
    paginationPackage() {
      let params = {
        queryFlag: this.packageState,
        current: this.current,
      };
      this.titleState();
      this.loading = true;
      paginationPackage(params).then((res) => {
        let data = res.data.data;
        this.tableData = data.records?.map((item) => {
          item.buttonList = [
            {
              name: "衣物详情",
              size: "medium",
              type: "text",
              code: "details",
              width: "180",
            },
          ];
          item.washDays = item.washDays + "";
          return item;
        });
        this.loading = false;
        this.total = data.total;
      });
    },
    //包裹详情
    packageDetails(row) {
      packageDetails(row.bundleNumber, 4).then((res) => {
        let data = res.data.data;
        this.dataList = data;
        let num = data.trajectoryList.length;
        this.calculationHeight(num);
        this.imageList = data.bundlePictureList ? data.bundlePictureList : [];
        this.dataList.bundleClothesDetailList = data.bundleClothesDetailList.map(
          (item) => {
            item.buttonList = [
              {
                name: "查看",
                size: "medium",
                type: "text",
                code: "details",
                width: "180",
              },
            ];
            item.orderOriginalPrice =
              item.originalPrice + "元/" + item.currentPrice + "元";
            if (item.squareNum != null) {
              item.squareNum = item.squareNum + "m²";
            }
            return item;
          }
        );
      });
    },
    calculationHeight(num) {
      let high = 45;
      switch (num) {
        case 1:
          high = 41;
          break;
        case 2:
          high = 32;
          break;
        case 3:
          high = 37;
          break;
        case 4:
          high = 40;
          break;
        case 5:
          high = 41;
          break;
        case 6:
          high = 43;
          break;
      }
      this.length = num - 1;
      this.height = high * num;
    },
    //表格按钮
    operationClick({ row, code }) {
      console.log(row, code);
      this.drawer = true;
      this.packageDetails(row);
    },
    //分页
    handleCurrentChange(val) {
      this.current =  val
      this.paginationPackage();
    },
    back() {
      this.$emit("back");
    },
    close() {
      this.drawer = false;
    },
    //详情方法
    seeClick() {
      this.viewSwitch = true;
    },
    switchClose() {
      this.viewSwitch = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.beforeTheFactory {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 20px 25px 0 25px;
  z-index: 100;
}
::v-deep .el-drawer {
  width: 100% !important;
}
.sendWashRecordView {
  position: fixed;
  left: 70px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  z-index: 100;
  padding: 20px 20px 25px 25px;
  overflow: auto;

  > .topView {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 20px 25px 0 25px;
    z-index: 100;

    > .backView {
      height: 30px;

      > .btn-back {
        font-size: 14px;
        color: #666;
        height: 30px;
        padding: 0;
        border: none;
        text-align: left;
      }
    }
  }
}
//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.9s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.9s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
