<template>
  <div class="takeClothesIndexView content-index">
    <div v-if="activateValue==0">
      <logisticsInStore />
    </div>
    <div v-if="activateValue==1">
      <oldStores />
    </div>
  </div>
</template>

<script>
import logisticsInStore from "./logisticsInStore/index.vue";
import oldStores from "./oldStores/index.vue";
import { enableLogistics } from "@/api/clothes/order";
export default {
  name: "",
  components: {
    logisticsInStore,
    oldStores,
  },
  data() {
    return {
       activateValue: null,
      InFactoryShow:null
    };
  },
   created() {
    this.getEnableLogistics()
  },
  destroyed(){
    this.activateValue=null
  },
  methods:{
    // 查询工厂所属区域是否开启物流
    getEnableLogistics() {
      enableLogistics().then(({ data }) => {
        if (data.code == 0) {
          if (this.InFactoryShow != data.data) {
            if (data.data == true) {
              this.activateValue = 0;
            } else {
              this.activateValue = 1;
            }
             this.InFactoryShow = data.data;
          }

        } else {
          this.$message.error(data.msg);
        }
      });
    },
  }
};
</script>

<style scoped>
.takeClothesIndexView{
  overflow: auto;
}
</style>
